import { Button, Form, Image, Input } from "antd";
import s from "./Fund.module.css";
import close from "../images/close.png";
import lava from "../images/lava.png";
import freekassa from "../images/freekassa.png";
import enot from "../images/enot.png";
import paypalych from "../images/payeer.png";
import aaio from "../images/aaio.png"
import p2pKassa from "../images/p2pKassa.png"
import nicepay from "../images/nicepay.png"
import { useState } from "react";
import { paymentsAPI } from "../API/paymentsAPI";

const Fund = (props) => {
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const { setFund } = props;

  const onFinish = (values) => {
      setIsLoading(true)
      paymentsAPI
      .createInvoice(values.amount, selected)
      .then((res) => {
        window.location.href = res.data
      })
      .finally(() => setIsLoading(false))
  };
  const fundClose = () => {
    props.setErrorFund(false);
    setFund(false);
  };

  return (
    <div>
      <div onClick={fundClose} className={s.modal_back}></div>
      <div className={s.window} style={{top: "55%"}}>
        <img
          onClick={fundClose}
          src={close}
          alt="close"
          className={s.close_img}
        />
        <div className={s.reg_title}>Пополнение баланса</div>
        <span className={s.add_title}>
          Введите сумму и выберите способ оплаты{" "}
        </span>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <>
            <div className={s.black_title}>Сумма пополнения</div>

            <div className={s.wrapper}>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input className={s.user_input} placeholder="" />
              </Form.Item>
            </div>

            <div>
              <div className={s.black_title}>Способ оплаты</div>
              <div className={s.plata}>
                {/* <div
                  className={s.plata_container}
                  style={
                    selected !== "lava"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("lava")}
                >
                  <img src={lava} className={s.plata_img} alt="lava" />

                  <div className={s.img_label}>LAVA</div>
                </div> */}
                <div
                  className={s.plata_container}
                  style={
                    selected !== "enot"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("enot")}
                >
                  <img src={enot} className={s.plata_img} alt="enot" />

                  <div className={s.img_label}>ENOT</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "freekassa"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("freekassa")}
                >
                  <img
                    className={s.plata_img}
                    src={freekassa}
                    alt="freekassa"
                  />

                  <div className={s.img_label}>FREEKASSA</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "payeer"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("payeer")}
                >
                  <img
                    className={s.plata_img}
                    src={paypalych}
                    alt="payeer"
                  />

                  <div className={s.img_label}>PAYEER</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "aaio"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("aaio")}
                >
                  <img
                    className={s.plata_img}
                    src={aaio}
                    alt="aaio"
                  />

                  <div className={s.img_label}>AAIO</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "p2pkassa"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("p2pkassa")}
                >
                  <img
                    className={s.plata_img}
                    src={p2pKassa}
                    alt="p2pkassa"
                  />

                  <div className={s.img_label}>P2PKassa</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "nicepay"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("nicepay")}
                >
                  <img
                    className={s.plata_img}
                    src={nicepay}
                    alt="nicepay"
                  />

                  <div className={s.img_label}>NICEPAY</div>
                </div>
              </div>
            </div>
            <h3 className={s.payment_info}>Если выбранная платежная система выдала ошибку, попробуйте повысить сумму платежа или выбрать другую.</h3>
            <h3 className={s.payment_info}>Платежные системы отличаются методами платежей, минимальной сумме платежа, а также комиссией.</h3>
            <div className={s.wrapper}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  disabled={(selected === "" || isLoading) ? true : false}
                  className={s.login_form_button}
                >
                  Перейти к оплате
                </Button>
              </Form.Item>
            </div>
          </>
        </Form>
      </div>
    </div>
  );
};

export default Fund;
