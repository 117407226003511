import React, { useState } from "react";
import s from "./Login.module.css";
import { Input, Checkbox, Form } from "antd";
import "antd/dist/reset.css";
import "../../index.css";
import { authAPI } from "../../API/authAPI";
import { useDispatch } from "react-redux";
import { getMeThunk } from "../../Redux/authReduser";
import { message } from "antd";
import close from "../../images/close.png"

const Login = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  
  const [focus, setFocus] = useState(false)
  const {setLogin,setPass,setTg} = props;
  
  const closeOpen = () =>{
    setLogin(false)
    setPass(true)
   }
   const closeLogOpenTg = () => {
    setLogin(false)
    setTg(true)
   }

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Ошибка входа",
    });
  };

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    const response = await authAPI.login(values.email, values.password);
    if (response === 200) {
      dispatch(getMeThunk());
      props.setLogin(false);
    } else {
      error();
    }
  };

  const [isTelegram, setIsTelegram] = useState(false);

  return (
    <div>
      {contextHolder}
      <div onClick={() => setLogin(false)} className={s.modal_back}></div>
      <div className={s.window}>
      <img  onClick={() => setLogin(false)} src={close} alt="close" className={s.close_img} />
        <div className={s.reg_title}>Авторизация</div>
        <span className={s.add_title}>Войдите в аккаунт используя данные  </span>
        <span className={s.add_title}> указнные при регистрации</span>
        <div className={s.black_title}>Электронная почта</div>
       
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          onChange={(v) => console.log(v)}
        >
          {isTelegram ? (
            <>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  className={s.user_input}

                  placeholder="Email"
                />
              </Form.Item>
              <div className={s.black_title}>Пароль</div>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input
                  className={s.user_input}

                  placeholder="Email"
                />
              </Form.Item></>
          ) : (
            <>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                
                <Input placeholder="Email" className={s.user_input} />
              </Form.Item>
              <div className={s.black_title}>Пароль</div>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                style={focus?{border: "1px solid  #FD125A",
                  boxShadow: "none", caretColor: "#FD125A", fontFamily: "Gilroy"}:{fontFamily: "Gilroy"}}
                  className={s.password_input}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              
              <div className={s.data_operations}>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox style={{ marginBottom: 15, color: "black" }}>
                  Запомнить меня
                </Checkbox>
              </Form.Item>
              <div  onClick={() =>closeOpen()} style={{color:"#FD125A"}}>Забыли пароль?</div>
              </div>


              <Form.Item>
               
                  <button className={s.login_form_button1}>
                    Войти
                  </button>
                  <div className={s.black_title}>Или</div>
                  <div className={s.telegram}>

                    <i
                      onClick={() => setIsTelegram(true)}
                      style={{
                        color: "#508df7",
                        cursor: "pointer",
                        marginLeft: "7px",
                      }}
                    ></i>
                  </div>
                  <button onClick={()=>closeLogOpenTg()} Click={() => setIsTelegram(true)} className={s.login_form_button}>
               Войти с помощью Telegram
          </button>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
export default Login;
